<template>
    <OModal name="TagsModal" @shown="tagModalOpen" @hidden="tagModalClose">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Tags</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="my-1">
                        <input v-model="searchQuery" class="form-control" placeholder="search..." type="text"
                            @keyup="handleSearch">
                    </div>
                    <div style="max-height: 600px; overflow-y: auto; overflow-x: hidden;">
                        <template v-if="tagTypes.length > 0">
                            <div v-for="type in tagTypes" class="pb-3 pt-1">
                                <h6 class="border-bottom">{{ type.Name }}</h6>
                                <div class="row">
                                    <template v-for="tag in tags">
                                        <div class="mb-1 col-lg-4 col-sm-6 col-xs-12" v-if="type.ID === tag.TagType_ID">
                                            <div class="form-check ms-1">
                                                <input type="checkbox" class="me-2 form-check-input" :id="tag.ID"
                                                    v-model="selectedTags[tag.ID]"
                                                    @click="setTag(tag.ID, !!!selectedTags[tag.ID])">
                                                <label class="text-normal form-check-label" :for="tag.ID">{{ tag.Name
                                                }}</label>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </template>
                        <div v-else-if="dsTags.state.isLoading" class="overlay">
                            <div class="overlay__inner">
                                <div class="overlay__content"><span class="spinner"></span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </OModal>
</template>
<script setup lang="ts">
import { getOrCreateDataObject } from 'o365.vue.ts';
import { ref } from 'vue';

const props = defineProps({
    selectedTags: {
        type: Object,
        required: true,
        default: {}
    },
    refresh: {
        type: Function,
        required: true,
        default: () => { },
    },
    procedureCall: {
        type: Function,
        required: true,
        default: () => { },
    }
}
);


const dsTags = getOrCreateDataObject({
    id: 'dsTags' + crypto.randomUUID(),
    viewName: 'aviw_Contract_Tags',
    maxRecords: -1,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields: [
        { name: "ID", type: "number" },
        { name: "Name", type: "string", "sortOrder": 1, "sortDirection": "asc" },
        { name: "TagTypeName", type: "string" },
        { name: "TagType_ID", type: "string" }
    ]
});

const dsTagTypes = getOrCreateDataObject({
    id: 'dsTagTypes' + crypto.randomUUID(),
    viewName: 'aviw_Contract_TagTypes',
    maxRecords: -1,
    whereClause: "HasTags = 1",
    loadRecents: false,
    distinctRows: true,
    fields: [
        { name: "ID", type: "number" },
        { name: "Name", type: "string", "sortOrder": 1, "sortDirection": "asc" },
        { name: "HasTags", type: "boolean" },
    ]
});

const tags = ref(dsTags.data);
const tagTypes = ref(dsTagTypes.data);
let searchQuery = ref("");
let isChanged = ref(false);

function handleSearch() {
    if (!searchQuery.value) {
        tags.value = dsTags.data;
        tagTypes.value = dsTagTypes.data;
        return;
    }
    else {
        let mySet = new Set();
        tags.value = dsTags.data.filter((el: any) => {
            let includesSearchQuery = el.Name.toLowerCase().includes(searchQuery.value.toLowerCase());
            if (includesSearchQuery) {
                mySet.add(el.TagTypeName);
            }
            return includesSearchQuery;
        });
        tagTypes.value = dsTagTypes.data.filter((x: any) => {
            return mySet.has(x.Name);
        })
    }
}

function tagModalClose() {
    if (isChanged.value) {
        props.refresh();
        isChanged.value = false;
    }
    searchQuery.value = "";
    tagTypes.value = dsTagTypes.data;
    tags.value = dsTags.data;
}

function tagModalOpen() {
    dsTags.load();
    dsTagTypes.load();
}


function setTag(id: number, isChecked: boolean) {
    isChanged.value = true;
    props.procedureCall(id, isChecked);
}

</script>